import { Grid, IconButton, Avatar, Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { createDownloadUrl } from "../util"

interface CommentReplyProps {
  text: string
  user?: {
    name: string
    profileImage?: {
      downloadUrl: string
    }
    _id: string
  }
}
/**
 * Comment Realy Component
 */
export default function CommentReply(props: CommentReplyProps) {
  const { text, user } = props
  const profileImageUrl = createDownloadUrl(user?.profileImage?.downloadUrl)

  return (
    <React.Fragment>
      <Grid item xs={1}></Grid>
      <Grid item xs={2}>
        <IconButton>
          <Link to={`/user/${user?._id}/portfolio`}>
            <Avatar alt={user?.name} src={profileImageUrl} />
          </Link>
        </IconButton>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="caption">{text}</Typography>
      </Grid>
    </React.Fragment>
  )
}
