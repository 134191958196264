/**
 * Audio Recorder
 * @returns AudioRecorder
 */
function AudioRecorder() {
  let audioRecorder: any = null

  const init = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      audioRecorder = {
        streamBeingCaptured: stream,
        mediaRecorder: new MediaRecorder(stream),
        audioBlobs: null,
      }

      audioRecorder.mediaRecorder.addEventListener("dataavailable", (event: { data: any }) => {
        audioRecorder.audioBlobs = event.data
      })

      const start = () => {
        audioRecorder.mediaRecorder.start()
      }

      const stop = () => {
        audioRecorder.mediaRecorder.stop()
        audioRecorder.streamBeingCaptured.getTracks().forEach((track: any) => track.stop())

        audioRecorder.mediaRecorder = null
        audioRecorder.streamBeingCaptured = null
      }

      return {
        startRecording: start,
        stopRecording: stop,
        on: (event: string, fn: any) => {
          audioRecorder.mediaRecorder.addEventListener(event, fn)
        },
        self: audioRecorder,
      }
    } catch (err) {
      return null
    }
  }

  return {
    init,
  }
}

export default AudioRecorder
