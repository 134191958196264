import { default as BluesPNG } from "../../assets/musicStyle/Blues.svg"
import { default as ClassicalPNG } from "../../assets/musicStyle/Classical.svg"
import { default as CountryPNG } from "../../assets/musicStyle/Country.svg"
import { default as DancePNG } from "../../assets/musicStyle/Dance.svg"
import { default as DiscoPNG } from "../../assets/musicStyle/Disco.svg"
import { default as EasyListeningPNG } from "../../assets/musicStyle/Easy_Listening.svg"
import { default as ElectronicPNG } from "../../assets/musicStyle/Electronic.svg"
import { default as FolkPNG } from "../../assets/musicStyle/Folk.svg"
import { default as FunkPNG } from "../../assets/musicStyle/Funk.svg"
import { default as GospelPNG } from "../../assets/musicStyle/Gospel.svg"
import { default as HeavyMetalPNG } from "../../assets/musicStyle/Heavy_Metal.svg"
import { default as HipHopPNG } from "../../assets/musicStyle/Hip_Hop.svg"
import { default as InstrumentalPNG } from "../../assets/musicStyle/Instrumental.svg"
import { default as JazzPNG } from "../../assets/musicStyle/Jazz.svg"
import { default as LatinPNG } from "../../assets/musicStyle/Latin.svg"
import { default as MusicTheatrePNG } from "../../assets/musicStyle/Theatre_Music.svg"
import { default as OperaPNG } from "../../assets/musicStyle/Opera.svg"
import { default as OrchestraPNG } from "../../assets/musicStyle/Orchestra.svg"
import { default as PopPNG } from "../../assets/musicStyle/Pop.svg"
import { default as PunkPNG } from "../../assets/musicStyle/Punk.svg"
import { default as RbPNG } from "../../assets/musicStyle/R&B.svg"
import { default as ReggaePNG } from "../../assets/musicStyle/Reggae.svg"
import { default as ReligiousPNG } from "../../assets/musicStyle/Religious.svg"
import { default as RockPNG } from "../../assets/musicStyle/Rock.svg"
import { default as SingingBluesPNG } from "../../assets/musicStyle/Singing_Blues.svg"
import { default as SoulPNG } from "../../assets/musicStyle/Soul.svg"
import { default as TechnoPNG } from "../../assets/musicStyle/Techno.svg"
import { default as WorldPNG } from "../../assets/musicStyle/World.svg"

// Mapping Icon with music style label
// Refer to: womucon-apps/packages/share/constant/musicStyle.js
export const renderIcon = (musicStyleLabel: string) => {
  switch (musicStyleLabel) {
    case "blues":
      return BluesPNG
    case "country":
      return CountryPNG
    case "easy listening":
      return EasyListeningPNG
    case "electronic":
      return ElectronicPNG
    case "folk":
      return FolkPNG
    case "rap":
      return HipHopPNG
    case "hip hop":
      return HipHopPNG
    case "jazz":
      return JazzPNG
    case "latin":
      return LatinPNG
    case "pop":
      return PopPNG
    case "r&b":
      return RbPNG
    case "soul":
      return SoulPNG
    case "rock":
      return RockPNG
    case "classical":
      return ClassicalPNG
    case "punk":
      return PunkPNG
    case "opera":
      return OperaPNG
    case "funk":
      return FunkPNG
    case "reggae":
      return ReggaePNG
    case "heavy metal":
      return HeavyMetalPNG
    case "disco":
      return DiscoPNG
    case "instrumental":
      return InstrumentalPNG
    case "techno":
      return TechnoPNG
    case "dance":
      return DancePNG
    case "religious":
      return ReligiousPNG
    case "world":
      return WorldPNG
    case "gospel":
      return GospelPNG
    case "musical theatre":
      return MusicTheatrePNG
    case "singing blues":
      return SingingBluesPNG
    case "orchestra":
      return OrchestraPNG
    default:
      console.log("No icon")
  }
}
