import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
} from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { colors } from "../../theme/colors"
import { createDownloadUrl } from "../../util"

import WMC_LOGO from "../../assets/brands/logo-sq.png"
import ShortcutIcon from "@mui/icons-material/Shortcut"
import TurnedInIcon from "@mui/icons-material/TurnedIn"
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot"
import BuyNowOrAddToCartButton from "../../component/BuyNowOrAddToCartButton/BuyNowOrAddToCartButton"
import DownloadButton from "../../component/DownloadButton/DownloadButton"
import { useNavigate } from "react-router-dom"
import { CartAPI, ProjectAPI, SavedMusicAPI, DownloadAPI } from "../../api"
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useCart } from "../../context/CartProvider"
import ShareToPopUp from "../../component/ShareToPopUp/ShareToPopUp"
type MusicDetailMusicCardProps = {
  _id: string
  name: string
  description: string
  imageUrl: string
  image: {
    downloadUrl: string
  }
  saved: boolean
  purchased: boolean
  price: number
  project: string
  savedMusics: string[]
  setSavedMusics: Dispatch<SetStateAction<string[]>>
  downloadUrl: string
  watermarkUrl: string
  musicType: string
}
/**
 * Music Detail Music Card Section
 */
export default function MusicDetailMusicCard(props: MusicDetailMusicCardProps) {
  const navigate = useNavigate()
  const { fetchCartItems } = useCart()
  const {
    _id,
    name,
    description,
    image,
    purchased,
    price,
    project,
    savedMusics,
    setSavedMusics,
    downloadUrl,
    watermarkUrl,
    musicType,
  } = props

  const [openQuotaDialog, setOpenQuotaDialog] = useState(false)
  const [projectDetail, setProjectDetail] = useState({
    users: [] as { role: string; user: { name: string; _id: string } }[],
  })

  useEffect(() => {
    const fetchProject = async () => {
      if (project) {
        const res = await ProjectAPI.getProject(project)
        if (res?.err) console.error(res.err)
        else setProjectDetail(res.res)
      }
    }
    fetchProject()
  }, [project])

  const projectImageUrl = createDownloadUrl(image?.downloadUrl) || WMC_LOGO

  const handleAddToCart = async () => {
    const res = await CartAPI.addToCart(_id)
    if (!res?.err) fetchCartItems()
  }

  const handleBuyNow = async () => {
    await handleAddToCart()
    navigate(`/shoppingCart`)
  }

  const handleSaveMusic = async () => {
    const res = await SavedMusicAPI.saveMusic(_id)
    if (!res?.err) setSavedMusics([...savedMusics, _id])
  }

  const handleUnsaveMusic = async () => {
    const res = await SavedMusicAPI.unsaveMusic(_id)
    if (!res?.err) setSavedMusics(savedMusics.filter((id) => id !== _id))
  }

  const saved = useMemo(() => savedMusics.includes(_id), [_id, savedMusics])
  const [openShareDialog, setOpenShareDialog] = useState(false)

  const handleDownload = async () => {
    let fileUrl = ""
    let url = ""
    try {
      const response = await DownloadAPI.recordDownload(_id)
      if (response?.err) {
        console.error("Failed to download music")
        return
      }
      if (response.res.success === false) {
        setOpenQuotaDialog(true)
        return
      }
      console.log("downloaded:", response)
      url = response.res.watermarkUrl ?? response.res.downloadUrl
    } catch (err) {
      console.error("Failed to download music:", err)
    }

    fileUrl = createDownloadUrl(url)

    if (!fileUrl) return

    try {
      // Fetch the file as a blob
      const response = await fetch(fileUrl)
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      const blob = await response.blob()

      // Create a temporary URL
      const url = URL.createObjectURL(blob)

      // Create a link element
      const a = document.createElement("a")
      a.href = url
      // Set a default file name or use something dynamic
      a.download = name ? `${name}${purchased ? "" : "-wm"}.mp3` : "downloaded_file.mp3"
      document.body.appendChild(a)
      a.click()

      // Clean up
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Download failed:", error)
    }
  }
  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: 345,
        width: "100%",
        m: 1,
        backgroundColor: colors.darkGrey2,
        borderRadius: 2,
        boxShadow: 3,
        overflow: "hidden",
      }}
    >
      {/* Price Tag */}

      <Box
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          backgroundColor: colors.orange,
          color: "#fff",
          padding: "2px 8px",
          borderRadius: "8px",
          fontSize: "0.8rem",
          fontWeight: "bold",
        }}
      >
        {purchased
          ? "PURCHASED"
          : musicType === "STANDARD"
            ? "STANDARD"
            : musicType === "SOUNDEFFECT"
              ? "Sound Effect"
              : `HKD ${price ?? 0}`}
      </Box>

      {/* Card Image */}
      <Link onClick={() => navigate(`/musicDetail/${_id}`)}>
        <CardMedia
          component="img"
          image={projectImageUrl}
          title="Project Image"
          sx={{
            height: { xs: 180, sm: 250, md: 300 },
            width: "100%",
            objectFit: "cover",
          }}
        />
      </Link>

      {/* Content */}
      <Box p={2}>
        <Typography variant="h6" color="white" gutterBottom>
          <Link
            sx={{ textDecoration: "none", color: "white", cursor: "pointer" }}
            onClick={() => navigate(`/musicDetail/${_id}`)}
          >
            {name}
          </Link>
        </Typography>
        <Typography variant="body2" color={colors.grey2}>
          {description}
        </Typography>
        <Typography
          variant="caption"
          color={colors.orange}
          sx={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/user/${
                projectDetail?.users?.find((t) => t?.role === "LEAD")?.user?._id ?? ""
              }/portfolio`,
            )
          }
        >
          {projectDetail?.users?.find((t) => t?.role === "LEAD")?.user?.name ?? ""}
        </Typography>
      </Box>

      {/* Actions */}
      <Box display="flex" justifyContent="space-between" alignItems="center" px={2} pb={2}>
        {purchased || musicType === "STANDARD" || musicType === "SOUNDEFFECT" ? (
          <DownloadButton onClick={handleDownload} />
        ) : (
          <BuyNowOrAddToCartButton
            onBuyNowClick={handleBuyNow}
            onAddToCartClick={handleAddToCart}
          />
        )}

        <Box display="flex">
          <IconButton onClick={() => setOpenShareDialog(true)}>
            <ShortcutIcon />
          </IconButton>
          <IconButton onClick={() => (saved ? handleUnsaveMusic() : handleSaveMusic())}>
            {saved ? <TurnedInIcon /> : <TurnedInNotIcon />}
          </IconButton>
        </Box>
      </Box>

      {/* Share Dialog */}
      <ShareToPopUp
        open={openShareDialog}
        onClose={() => setOpenShareDialog(false)}
        musicId={_id}
        musicName={name}
      />
      <Dialog open={openQuotaDialog} onClose={() => setOpenQuotaDialog(false)}>
        <DialogTitle>Download Quota Reached</DialogTitle>
        <DialogContent>You have reached your download quota this month. Upgrade Now!</DialogContent>
        <DialogActions>
          {/* <Button onClick={() => navigate("/subscription")}>Upgrade Plan</Button> */}

          <Button onClick={() => setOpenQuotaDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
