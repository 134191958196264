import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { colors } from "../../theme/colors"
import { createDownloadUrl } from "../../util"

type MusicDetailInformationProps = {
  instruments: string[]
  image?: {
    downloadUrl: string
  }
  metreUpper: number
  metreLower: number
  tempo: string
  styles: string[]
  musicKey: string
}
/**
 * Music Detail Information Section
 */
export default function MusicDetailInformation(props: MusicDetailInformationProps) {
  const { instruments, image, metreUpper, metreLower, tempo, styles, musicKey } = props

  const projectImageUrl = createDownloadUrl(image?.downloadUrl)

  return (
    <Grid item container padding={2} xs={12} marginTop={2} bgcolor={colors.grey3}>
      <Grid item xs={12}>
        <Typography variant="h5" color={colors.teal}>
          Information
        </Typography>
      </Grid>
      <Grid item md={4}>
        <Typography variant="subtitle2" color={colors.grey2}>
          Style
        </Typography>
        <Typography variant="body1">{styles?.map((style: string) => style).join(", ")}</Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" color={colors.grey2}>
          Instruments
        </Typography>
        <Typography variant="body1">
          {instruments?.map((instrument: string) => instrument).join(", ")}
        </Typography>
      </Grid>
      <Grid item md={4}></Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" color={colors.grey2}>
          Tempo
        </Typography>
        <Typography variant="body1">{tempo}</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" color={colors.grey2}>
          Music Key
        </Typography>
        <Typography variant="body1">{musicKey}</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" color={colors.grey2}>
          Metre
        </Typography>
        <Typography variant="body1">{`${metreUpper}/${metreLower}`}</Typography>
      </Grid>
    </Grid>
  )
}
