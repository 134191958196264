import { Box, Button, Typography } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { FilterState } from "../../../types/marketPlace"
import FilterMenuItem from "./FilterMenuItem"
import { startCase } from "lodash"
import { rbStringHandling } from "../../../util"
// @ts-ignore
import { musicStyle } from "@womucon/share"

/**
 * Filter menu in market place
 */
export default function MarketPlaceFilterMenu({
  filter,
  setFilter,
}: {
  filter: FilterState
  setFilter: Dispatch<SetStateAction<FilterState>>
}) {
  const styleArray = filter?.style ?? []

  const updateFilter = (newStyle: string) => {
    if (!newStyle) return
    setFilter((prevFilter) => {
      const updatedStyles = prevFilter?.style?.includes(newStyle)
        ? prevFilter?.style?.filter((style) => style !== newStyle)
        : [...prevFilter.style, newStyle]
      return { ...prevFilter, style: updatedStyles }
    })
  }

  const resetFilter = () => {
    setFilter((prevFilter) => {
      return { ...prevFilter, style: [] }
    })
  }

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="body1">Filter by music style</Typography>
        <Button sx={{ textTransform: "none" }} onClick={() => resetFilter()}>
          Clear All
        </Button>
      </Box>

      <Box>
        {musicStyle?.map((item: string) => {
          const focused = styleArray?.includes(item)
          return (
            <FilterMenuItem
              key={`style_${item}`}
              image={item}
              focused={focused}
              label={item === "r&b" ? rbStringHandling(item) : startCase(item)}
              onClick={() => updateFilter(item)}
            />
          )
        })}
      </Box>
    </Box>
  )
}
