import React, { useEffect, useState } from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { Button, Divider, Grid, Link, Typography, Box, useMediaQuery } from "@mui/material"
import All from "./tab/All"
import { Page, PageContainer, SwipeableListGroup } from "../../component"
import {
  MusicCreationBanner,
  WebThreeBanner,
  CreateAndEarnBanner,
  LaunchPartyBanner,
  JamSessionComingSoonBanner,
} from "../../assets/carousel"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import Premium from "./tab/Premium"
import Standard from "./tab/Standard"
import SoundEffect from "./tab/SoundEffect"
import { useCheckIsAdmin } from "../../hooks/useCheckIsAdmin"
import { colors } from "../../theme/colors"
import { SubscriptionAPI } from "../../api"

type MarketPlaceTab = "all" | "premium" | "standard" | "soundEffect"

const CAROUSEL_HEIGHT = "auto"

const CAROUSEL_ITEMS = [
  {
    label: "music creation no limit",
    imageSrc: MusicCreationBanner,
  },
  {
    label: "web 3 music ecosystem",
    imageSrc: WebThreeBanner,
  },
  {
    label: "create and earn now",
    imageSrc: CreateAndEarnBanner,
  },
  {
    label: "launch party november 2024",
    imageSrc: LaunchPartyBanner,
  },
  {
    label: "jam session coming soon",
    imageSrc: JamSessionComingSoonBanner,
  },
]

const MARKETPLACE_TABS = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "premium",
    label: "Premium",
  },
  {
    value: "standard",
    label: "Standard",
  },
  {
    value: "soundEffect",
    label: "Sound effect",
  },
] as const

/**
 * Market place page
 */
export default function MarketPlacePage() {
  const isMobile = useMediaQuery("(max-width:600px)")
  const { isAdmin } = useCheckIsAdmin()

  const [activeTab, setActiveTab] = useState<MarketPlaceTab>("all")
  const [showMessage, setShowMessage] = useState(false) // Control message visibility
  const [subscribed, setSubscribed] = useState(true)
  useEffect(() => {
    const checkSubscription = async () => {
      const response = await SubscriptionAPI.checkSubscription()
      if (response?.err) {
        return
      }
      setSubscribed(response?.res.success)
    }
    checkSubscription()
  }, [])

  useEffect(() => {
    if (!subscribed) {
      setShowMessage(true)
    }
  }, [subscribed])
  const handleDismiss = () => {
    setShowMessage(false)
  }

  return (
    <div>
      <Page pageId={MarketPlacePage} noPadding>
        <PageContainer>
          <Grid item xs={12} alignItems={"center"}>
            <SwipeableListGroup
              containerHeight={CAROUSEL_HEIGHT}
              slideWidth={isMobile ? "100%" : "75%"}
              slideHeight={"auto"}
              gap={isMobile ? 0 : -261}
              items={CAROUSEL_ITEMS}
            />
          </Grid>

          <Tabs
            value={activeTab}
            onChange={(event: React.SyntheticEvent, tab: MarketPlaceTab) => setActiveTab(tab)}
            textColor="primary"
            indicatorColor="primary"
          >
            {MARKETPLACE_TABS.map(({ value, label }) => {
              if (!isAdmin && value === "premium") return null
              return <Tab key={value} value={value} label={label} sx={{ textTransform: "none" }} />
            })}
          </Tabs>

          <Divider sx={{ mb: 2 }} />

          {activeTab === "all" && <All />}
          {isAdmin && activeTab === "premium" && <Premium />}
          {activeTab === "standard" && <Standard />}
          {activeTab === "soundEffect" && <SoundEffect />}
        </PageContainer>
      </Page>

      {showMessage && !isAdmin && (
        <Box
          sx={{
            position: "fixed",
            bottom: 60,
            right: 10,
            width: isMobile ? "90%" : "350px",
            padding: 2,
            backgroundColor: colors.grey3,
            boxShadow: 3,
            borderRadius: 4,
            zIndex: 1000,
            textAlign: "center",
          }}
        >
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            Subscribe now to download unlimited songs without a watermark!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            href="/subscription"
            sx={{ textDecoration: "none", marginBottom: 1 }}
          >
            Subscribe Now
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={{ textDecoration: "none" }}
            onClick={handleDismiss}
          >
            Maybe later
          </Button>
        </Box>
      )}

      {/* Admin Upload Button */}
      {isAdmin && (
        <Button
          component={Link}
          href="/uploadSong"
          type="submit"
          color={"primary"}
          variant="contained"
          sx={{
            position: "fixed",
            right: "0%",
            bottom: "0%",
            borderRadius: 30,
            py: 1.5,
            mr: 3,
            mb: 10,
            zIndex: 999,
          }}
          endIcon={<ArrowForwardIcon />}
        >
          Upload
        </Button>
      )}
    </div>
  )
}
