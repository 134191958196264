import { Box, Button, Stack, Typography } from "@mui/material"
import { SubscriptionAPI, SubscriptionPlanAPI } from "../../api"
import SubscribeSection from "../../component/SubscriptionSection/SubscriptionSection"
import { useEffect, useMemo, useState } from "react"
import { colors } from "../../theme/colors"

interface SubscriptionPlan {
  planType: string
  title: string
  price: number
  billingCycle: string
  features: string[]
  stripeId?: string
  isContactUs?: boolean
}
/**
 * Table for order music
 */
export default function SubscriptionTab() {
  const [subscribed, setSubscribed] = useState(false)
  const [planType, setPlanType] = useState("")
  const [plans, setPlans] = useState<SubscriptionPlan[]>([])
  const [, setError] = useState("")
  const [cancelled, setCancelled] = useState(false)
  const [cancelledAt, setCancelledAt] = useState("")
  const [subscription, setSubscription] = useState<any>()

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const response = await SubscriptionAPI.checkSubscription()
        if (response?.err) {
          setError(response?.err)
          return
        }
        setSubscribed(response?.res.success)
        setPlanType(response?.res.subscription?.planType)
        setCancelled(response?.res.subscription?.cancelled)
        setCancelledAt(response?.res.subscription?.cancelledAt)
        setSubscription(response?.res.subscription)
      } catch (error) {
        console.error("Error checking subscription:", error)
      }
    }

    const fetchPlans = async () => {
      try {
        const response = await SubscriptionPlanAPI.getAll()
        if (response?.err) {
          setError(response?.err)
          return
        }
        setPlans(response?.res?.plans || [])
      } catch (error) {
        console.error("Error fetching plans:", error)
      }
    }

    checkSubscription()
    fetchPlans()
  }, [])

  const planDetail = useMemo(() => {
    return plans.find((plan) => plan.planType === planType)
  }, [plans, planType])

  const handleCancelSubscription = async () => {
    const res = await SubscriptionAPI.cancel()
    if (!res?.err) {
      setCancelled(true)
      console.log(res)
      console.log(res?.res?.cancelledAt)
      setCancelledAt(res?.res?.cancelledAt)
    }
  }
  console.log(cancelledAt)
  return (
    <Stack
      sx={{
        padding: 2,
        marginTop: 4,
        width: "100%",
      }}
    >
      {subscribed ? (
        <Box sx={{ padding: 2, border: `1px solid ${colors.darkGrey2}` }}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {planDetail?.title} plan
            </Typography>
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
              <Typography variant="h4" sx={{ color: "#fff", marginRight: 1 }}>
                {planDetail?.price ? `$${planDetail.price}` : ""}
              </Typography>
              <Typography variant="body2" sx={{ color: "#aaa" }}>
                Billed {planDetail?.billingCycle}
              </Typography>
            </Box>
            {subscription?.endDate && (
              <Typography variant="body2" sx={{ color: "#aaa", marginTop: 1 }}>
                Current period ends on: {new Date(subscription.endDate).toLocaleDateString()}
              </Typography>
            )}
            {cancelled && (
              <Typography variant="body2" sx={{ color: "red", marginTop: 1 }}>
                This subscription has been cancelled on {new Date(cancelledAt).toLocaleDateString()}
                .
              </Typography>
            )}
            <Box
              component="ul"
              sx={{
                paddingY: 2,
                paddingX: 4,
                marginTop: 2,
                backgroundColor: colors.darkGrey2,
              }}
            >
              {planDetail?.features?.map((feature, index) => (
                <Typography
                  component="li"
                  variant="body2"
                  key={index}
                  sx={{ color: "#fff", marginBottom: 1 }}
                >
                  {feature}
                </Typography>
              ))}
            </Box>

            {!cancelled && (
              <Button variant="contained" onClick={handleCancelSubscription}>
                Cancel Subscription
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <SubscribeSection />
      )}
    </Stack>
  )
}
