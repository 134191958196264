import { getRequest, postRequest } from "./request"

const Subscription: any = {}

Subscription.add = async (planType: string) => {
  const data = {
    planType,
  }
  return await postRequest({ url: "/subscription/add", data })
}

Subscription.getDetail = async (subscriptionId: string) => {
  return await getRequest({ url: `/subscription/detail/${subscriptionId}` })
}

Subscription.cancel = async () => {
  return await postRequest({ url: `/subscription/cancel` })
}

Subscription.checkSubscription = async () => {
  return await getRequest({ url: `/subscription/check` })
}

export default Subscription
