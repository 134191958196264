import { useState, useCallback, useEffect } from "react"

interface Validation {
  /**
   *  Contains Validation logic
   */
  validate: {
    /**
     *  Given the form field as the argument, determines if it meets the form requirement
     */
    isValid: (_: any) => boolean
    /**
     *  Message to appear when field is invalid
     */
    message: string
  }
}

export type ErrorRecord<T> = Partial<Record<keyof T, string>>

export type Validations<T> = Partial<Record<keyof T, Validation>>

export type FormErrorDisplay<T> = Partial<Record<keyof T, boolean>>

/**
 * This function will take an initialState arg to initialize a state and a validations arg containing the validate function and a message.
 *
 * A validateForm() is called inside the life cycle of this hook whenever the formState is changed, or if validateForm() is called by component using this hook.
 * Any given value within the formState that result to false in its own validate function during validateField() will have its validate message set in the errors state and returned by the hook.
 *
 * @param validations validation object that contains a validation function and an error message
 * @param initialState initial state to use for the internal formState
 * @returns formState - state managed by this hook.
 *          setFormState - a react setState equivilent that update the formState.
 *          errors - contains any validation failure in key value pairs that is also partial to the given type T.
 *          validateForm - function that component using this hook could call to run the validation.
 *
 *!!!!!!!!!!!!
 *
 * As the reference to the object is replaced on every state update,
 * it is recommended to use this hooks with React.memo if you have a complex form state to avoid unnecessary rerendering
 *
 * !!!!!!!!!!!!
 */

export default function useFormValidation<T>(validations: Validations<T>, initialState: T) {
  const mapErrorMap = Object.keys(validations).reduce((prev, current) => {
    return { ...prev, [current]: false }
  }, {})

  const [errors, setErrors] = useState<ErrorRecord<T>>({})
  const [formState, setFormState] = useState<T>(initialState)
  const [formErrorDisplay, setFormErrorDisplay] = useState<FormErrorDisplay<T>>(mapErrorMap)

  const updateFormState = useCallback((nextState: Partial<T>) => {
    setFormState((prevState) => ({ ...prevState, ...nextState }))
  }, [])

  const updateFormErrorDisplay = useCallback((nextState: Partial<FormErrorDisplay<T>>) => {
    setFormErrorDisplay((prevState) => ({ ...prevState, ...nextState }))
  }, [])

  /**
   * This function will check if ANY of the field provided exists in the error object.
   *
   * @param fields key of field
   * @returns boolean
   */
  const hasErrorInForm = useCallback(
    (fields: Array<keyof T>): boolean => {
      return fields.some((field) => {
        return errors[field as keyof T]
      })
    },
    [errors],
  )

  /**
   * Calls if form is valid, this does not make use of the error object
   *
   * @returns boolean
   */
  const isFormValid = useCallback((): boolean => {
    for (const key in validations) {
      const value = formState[key]
      const validation = validations[key] as Validation
      const { isValid: isFieldValid } = validation.validate
      if (!isFieldValid(value)) {
        return false
      }
    }
    return true
  }, [formState, validations])

  /**
   * Check if form is valid and update the error state by adding/removing the error in state
   *
   */
  const validateField = useCallback(() => {
    let isFormValid = true
    const newErrors: ErrorRecord<T> = {}
    for (const key in validations) {
      const value = formState[key]
      const validation = validations[key] as Validation
      const { isValid: isFieldValid, message } = validation.validate
      if (!isFieldValid(value)) {
        isFormValid = false
        newErrors[key] = message
      }
    }

    if (!isFormValid) {
      setErrors(newErrors)
      return
    }
    setErrors({})
  }, [formState, validations])

  useEffect(() => {
    // Ensure formState is not empty object before proceeding
    if (Object.keys(formState as object).length) {
      validateField()
    }
  }, [validateField, formState])

  return {
    validateField,
    updateFormState,
    errors,
    formState,
    hasErrorInForm,
    isFormValid,
    formErrorDisplay,
    updateFormErrorDisplay,
  }
}
