import React from "react"
import { screen, RenderResult } from "@testing-library/react"

export function getDisplayName(component: React.ComponentType<any>) {
  return component.displayName || component.name
}

export function getRootElement(renderResult: RenderResult): HTMLElement {
  if (!renderResult.container.firstChild) {
    throw new Error("Component did not render a root element")
  }

  if (renderResult.container.children.length !== 1) {
    throw new Error(
      "component rendered multiple sibling elements at the root, rather than a single element",
    )
  }

  return renderResult.container.firstChild as HTMLElement
}

export function waitForScreenToLoad<T>(
  screenComponent: string | React.ComponentType<T>,
): Promise<HTMLElement> {
  const testId: string =
    typeof screenComponent === "string" ? screenComponent : getDisplayName(screenComponent)

  if (!testId) {
    throw new Error("Unable to get display name for provided component")
  }

  return screen.findByTestId(testId)
}
