import { getRequest, postRequest } from "./request"

const Task: any = {}

Task.createTask = async (projectId: string, rawData: any) => {
  const data = {
    ...rawData,
  }
  return postRequest({
    url: `/task`,
    data,
  })
}

Task.getTasks = async (projectId: string) => {
  return getRequest({ url: `/task/project/${projectId}` })
}

Task.editTask = async (taskId: string, rawData: any) => {
  const params = {
    method: "PUT",
  }
  const data = {
    ...rawData,
  }
  return postRequest({
    url: `/task/${taskId}`,
    params,
    data,
  })
}

Task.deleteTask = async (taskId: string) => {
  const params = {
    method: "DELETE",
  }
  return postRequest({
    url: `/task/${taskId}`,
    params,
  })
}

export default Task
