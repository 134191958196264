import React from "react"
import Typography from "@mui/material/Typography"
import { Chip } from "@mui/material"
import DownloadIcon from "@mui/icons-material/Download"
import AttachFileIcon from "@mui/icons-material/AttachFile"

type FileProps = {
  instrument: string
  fileName?: string
  downloadUrl: string
  onDelete?: () => {}
}

export default function File({ instrument, fileName, downloadUrl, onDelete }: FileProps) {
  return (
    <React.Fragment>
      <Typography variant="subtitle1">{instrument}</Typography>
      <Chip
        icon={<AttachFileIcon />}
        label={`${fileName}`}
        onClick={() => {
          window.open(downloadUrl, "_blank")
        }}
        onDelete={onDelete}
        deleteIcon={<DownloadIcon />}
        variant="outlined"
      />
    </React.Fragment>
  )
}
