/* eslint-disable jsx-a11y/media-has-caption */
import React from "react"
import { Box, Checkbox, Grid, IconButton, Popover, Typography } from "@mui/material"
import { ClickAwayListener } from "@mui/base"
import DownloadIcon from "@mui/icons-material/Download"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import DeleteIcon from "@mui/icons-material/Delete"

const audioPlayerStyle = {
  width: "100%",
  border: "1px solid white",
  borderRadius: "15px",
  paddingY: 1,
  paddingX: 2,
  " p": {
    paddingTop: 1,
  },
  audio: {
    height: "25px",
    width: "100%",
  },
}

type AudioPlayerProps = {
  instrument: string
  fileName?: string
  src: string
  fileId: string
  colors: any
  handleDelete: (_id: string) => void
}

/**
 * Audio Player Component
 */
export default function AudioPlayer({
  fileId,
  instrument,
  fileName,
  colors,
  src,
  handleDelete,
}: AudioPlayerProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "project-detail-audio-more" : undefined
  return (
    <Box sx={audioPlayerStyle}>
      <Grid container>
        <Grid item xs={2}>
          <Checkbox />
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body1" sx={{ overflow: "hidden" }}>
            {instrument}: <br />
            {fileName}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <IconButton sx={{ float: "right" }} aria-describedby={id} onClick={handleClick}>
            <MoreVertIcon sx={{ color: colors.orange }} />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ p: 1 }} onMouseLeave={handleClose}>
                  <IconButton onClick={() => handleDelete(fileId)}>
                    <DeleteIcon sx={{ color: colors.orange }} />
                  </IconButton>
                </Box>
              </ClickAwayListener>
            </Popover>
          </IconButton>
          <IconButton sx={{ float: "right" }} onClick={() => window.open(src, "_blank")}>
            <DownloadIcon sx={{ color: colors.orange }} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <audio controls controlsList="nodownload" preload="metadata">
            <source src={src} />
          </audio>
        </Grid>
      </Grid>
    </Box>
  )
}
