import React from "react"
import { Button, Dialog } from "@mui/material"
import ReactCrop, { Crop, PixelCrop } from "react-image-crop"
import { canvasPreview, imgPreviewToFile } from "./ImageCropData"
import { colors } from "../../theme/colors"
import useMUIMediaQuery from "../../hooks/useMUIMedia"

interface ImageCropProps {
  imgSrc: string
  handleSaveImage: (_url: string, _file: File) => void
  circularCrop?: boolean
}

/**
 * Image Corp
 */
export default function ImageCrop({ imgSrc, handleSaveImage, circularCrop }: ImageCropProps) {
  const imgRef = React.useRef<HTMLImageElement>(null)
  const defaultCrop: PixelCrop = { x: 0, y: 0, width: 200, height: 200, unit: "px" }
  const [crop, setCrop] = React.useState<Crop>(defaultCrop)
  const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>(defaultCrop)

  const saveImage = async () => {
    if (!imgRef.current || !crop || !completedCrop) return

    const canvas = document.createElement("canvas")
    canvasPreview(imgRef.current, canvas, completedCrop)

    const file = await imgPreviewToFile(canvas)
    if (!file) return

    const url = URL.createObjectURL(file)
    handleSaveImage(url, file)
  }

  const handleCropImage = (c: PixelCrop) => {
    setCrop(c)
    setCompletedCrop(c)
  }

  return (
    <>
      <ReactCrop
        aspect={1}
        crop={crop}
        circularCrop={circularCrop}
        onChange={handleCropImage}
        style={{ alignItems: "center", justifyItems: "center" }}
      >
        <img ref={imgRef} alt="upload-img" src={imgSrc} />
      </ReactCrop>
      <Button variant="contained" onClick={() => saveImage()}>
        Save
      </Button>
    </>
  )
}

/**
 * Modal for cropped image preview
 */
// eslint-disable-next-line react/no-multi-comp
export function ImageCropModal({
  open,
  children,
}: {
  open: boolean
  fullScreen?: boolean
  children: React.ReactNode
}) {
  const { isMobile } = useMUIMediaQuery()
  return (
    <Dialog open={open} sx={{ backgroundColor: isMobile ? colors.darkGrey2 : undefined }}>
      {children}
    </Dialog>
  )
}
