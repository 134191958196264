import React from "react"
import { createTheme } from "@mui/material/styles"
import { colors } from "./colors"

declare module "@mui/material/styles" {
  interface TypographyVariants {
    formTitle: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    formTitle?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    formTitle: true
  }
}

const { orange, charcoal, teal } = colors

export const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: charcoal,
      paper: charcoal,
    },
    primary: { main: orange, contrastText: "#ffffff" },
    secondary: { main: "#ffffff", contrastText: "#000000" },
  },
  typography: {
    fontFamily: [
      "Josefin Sans",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    formTitle: {
      color: teal,
      fontSize: 24,
    },
  },
})
