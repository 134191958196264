import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { colors } from "../../theme/colors"
import WMC_LOGO from "../../assets/brands/logo-sq.png"
import { UploadSongFormStateProps } from "../../types"
import _ from "lodash"
import { Stack } from "@mui/material"

const infoContainerStyle = {
  " & h5": {
    color: colors.teal,
  },
  " & h6": {
    color: colors.grey,
  },
}

/**
 * Upload song info for preview
 */
export default function UploadSongInfo(
  props: UploadSongFormStateProps & { imagePreviewUrl: string },
) {
  const {
    name,
    description,
    tempo,
    metreLower,
    metreUpper,
    musicKey,
    styles,
    instruments,
    musicType,
    currency,
    price,
    imagePreviewUrl,
  } = props

  return (
    <Grid container sx={infoContainerStyle} spacing={{ xs: 0, lg: 4 }}>
      <Grid item xs={12} lg={3}>
        <img src={imagePreviewUrl || WMC_LOGO} alt="project img" width="100%" />
      </Grid>

      <Grid item container xs={12} lg={9} spacing={2}>
        <Grid item xs={12} pb={{ xs: 0, lg: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">{name ? name : "--"}</Typography>
            {musicType && (
              <Typography
                variant="body2"
                bgcolor={colors.grey3}
                p={1}
                borderRadius={1}
                alignSelf={"center"}
              >
                {musicType === "PREMIUM"
                  ? `${currency} ${price}`
                  : musicType === "STANDARD"
                    ? "STANDARD"
                    : musicType === "SOUNDEFFECT"
                      ? "SOUND EFFECT"
                      : "--"}
              </Typography>
            )}
          </Stack>
          <Typography variant="body1">{description ? description : "--"}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Information</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">Style</Typography>
          <Typography variant="body1">
            {_.isEmpty(styles)
              ? "--"
              : styles?.map((style: string) => style?.capitalize()).join(", ")}
          </Typography>
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="subtitle2">Instruments</Typography>
          <Typography variant="body1">
            {_.isEmpty(instruments)
              ? "--"
              : instruments?.map((instrument: string) => instrument?.capitalize()).join(", ")}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">Music Key</Typography>
          <Typography variant="body1">{musicKey}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">Tempo</Typography>
          <Typography variant="body1">{tempo}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">Metre</Typography>
          <Typography variant="body1">{`${metreUpper}/${metreLower}`}</Typography>
        </Grid>

        {/* <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Music Type</Typography>
          <Typography variant="body1">{"--"}</Typography>
        </Grid> */}
      </Grid>
    </Grid>
  )
}
