// src/context/MusicPlayerProvider.tsx
import React, { createContext, useContext, useState } from "react"
import { MarketPlaceItem } from "../types/marketPlace"

export type MusicContextProps = {
  currentSong: MarketPlaceItem | null
  isPlaying: boolean
  playSong: (_music: MarketPlaceItem) => void
  stopSong: () => void
  setVolume: (_olume: number) => void
  volume: number
  duration: number
  currentTime: number
  setDuration: (_duration: number) => void
  setCurrentTime: (_time: number) => void
  setIsPlaying: (_isPlaying: boolean) => void
}

const MusicContext = createContext<MusicContextProps | undefined>(undefined)

/**
 * MusicProvider component
 */
export default function MusicProvider({ children }: { children: React.ReactNode }) {
  const [currentSong, setCurrentSong] = useState<MarketPlaceItem | null>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [volume, setVolume] = useState<number>(1)
  const [duration, setDuration] = useState<number>(0)
  const [currentTime, setCurrentTime] = useState<number>(0)

  const playSong = (music: MarketPlaceItem) => {
    setCurrentSong(music)
    setIsPlaying(true)
  }

  const stopSong = () => {
    setIsPlaying(false)
  }

  return (
    <MusicContext.Provider
      value={{
        currentSong,
        isPlaying,
        playSong,
        stopSong,
        setVolume,
        volume,
        duration,
        currentTime,
        setDuration,
        setCurrentTime,
        setIsPlaying,
      }}
    >
      {children}
    </MusicContext.Provider>
  )
}

/**
 * Custom hook to use the MusicPlayerContext
 */
export function useMusicPlayer() {
  const context = useContext(MusicContext)
  if (context === undefined) {
    throw new Error("useMusicPlayer must be used within a MusicPlayerProvider")
  }
  return context
}
