/* eslint-disable no-unreachable */
import { Box, Button, Grid, LinearProgress, Link, Typography } from "@mui/material"
import * as React from "react"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { MusicAPI, SavedMusicAPI } from "../../api"
import { Page } from "../../component"
import MusicDetailInformation from "../../component/MusicDetailInformation/MusicDetailInformation"
import MusicDetailMusic from "../../component/MusicDetailMusic/MusicDetailMusic"
import MusicDetailMusicCard from "../../component/MusicDetailMusicCard/MusicDetailMusicCard"
import { useUserInfo } from "../../context"

const musicDetailInitProps = {
  _id: "",
  users: [],
  downloadUrl: "",
  instruments: [],
  project: undefined,
  name: "",
  price: 0,
  view: 0,
  like: [],
  purchased: false,
  styles: [],
  projectName: "",
  musicType: "",
  purchasedCount: 0,
}
interface MusicDetail {
  _id: string
  users: []
  downloadUrl: ""
  instruments: []
  project: undefined
  name: ""
  price: 0
  view: 0
  like: []
  purchased: false
  styles: []
  projectName: string
  musicType: string
  purchasedCount: number
}

interface ProjectDetail {
  _id: undefined
  users: { role: string; user: { name: string; _id: string } }[]

  instrumentSheet: string[]
  files: string[]
  fileToken: string
  instruments: string[]
  metreUpper: number
  metreLower: number
  tempo: string
  styles: string[]
  musicKey: string
  description: string
  image: string
  name: string
}
const projectDetailInitProps = {
  _id: undefined,
  users: [] as { role: string; user: { name: string; _id: string } }[],
  comments: [],
  musicSheet: {},
  instrumentSheet: [],
  files: [],
  fileToken: undefined,
  instruments: [],
  metreUpper: 0,
  metreLower: 0,
  tempo: "",
  styles: [],
  musicKey: "",
  description: "",
  image: undefined,
  name: "",
}

/**
 * Music Detail page
 */
export default function MusicDetailPage() {
  const { musicId } = useParams()
  const navigate = useNavigate()
  const { userToken } = useUserInfo()
  const [pageState, setPageState] = React.useState({
    musicDetail: musicDetailInitProps,
    projectDetail: projectDetailInitProps,
    recommendedUsers: [],
    projectDetailUpdate: 1,
    tasks: [],
    tasksUpdate: 1,
    error: undefined,
  })
  const [savedMusics, setSavedMusics] = useState<string[]>([])

  const [similarMusics, setSimilarMusics] = React.useState([])
  const [musicsOfUser, setMusicsOfUser] = React.useState([])
  React.useEffect(() => {
    const trigger = async () => {
      const getMusicRes = userToken
        ? await MusicAPI.getMusicDetailWithPurchased(musicId)
        : await MusicAPI.getMusicDetail(musicId)
      if (getMusicRes.err) {
        updatePageState({ error: getMusicRes.err })
        return
      }
      updatePageState({
        musicDetail: getMusicRes.res,
      })
    }

    trigger()
  }, [musicId, pageState.projectDetailUpdate, userToken])

  const updatePageState = (newState: any) => {
    setPageState((prevState) => {
      return {
        ...prevState,
        ...newState,
      }
    })
  }

  const { musicDetail, error, projectDetail } = pageState
  React.useEffect(() => {
    const trigger = async () => {
      if (musicDetail.styles.length > 0) {
        const getMusics = userToken
          ? await MusicAPI.retrieveRandomByStylesWithPurchased(musicDetail.styles)
          : await MusicAPI.retrieveRandomByStyles(musicDetail.styles)
        console.log(getMusics)
        if (getMusics.err) {
          updatePageState({ error: getMusics.err })
          return
        }
        setSimilarMusics(getMusics.res)
      }
    }

    trigger()
  }, [pageState.projectDetailUpdate, musicDetail, userToken, musicId])

  React.useEffect(() => {
    const trigger = async () => {
      if (musicDetail.project) {
        const getProjectRes = musicDetail.project

        updatePageState({
          projectDetail: getProjectRes,
        })
      }
    }

    trigger()
  }, [pageState.projectDetailUpdate, musicDetail])
  React.useEffect(() => {
    const trigger = async () => {
      if (projectDetail?.users?.length > 0) {
        const getMusics = userToken
          ? await MusicAPI.retrieveRandomByUserWithPurchased(
              projectDetail?.users.find((t) => t.role === "LEAD")?.user._id
            )
          : await MusicAPI.retrieveRandomByUser(
              projectDetail?.users.find((t) => t.role === "LEAD")?.user._id
            )
        console.log(getMusics)
        if (getMusics.err) {
          updatePageState({ error: getMusics.err })
          return
        }
        setMusicsOfUser(getMusics.res)
      }
    }

    trigger()
  }, [pageState.projectDetailUpdate, musicDetail, userToken, musicId, projectDetail?.users])
  useEffect(() => {
    const fetchSavedMusics = async () => {
      try {
        const response = await SavedMusicAPI.getSavedMusic()
        if (response?.err) {
          console.error("Failed to get saved musics")
          return
        }
        console.log("Saved musics:", response)
        setSavedMusics(response.res.map((t: { musicId: { _id: string } }) => t.musicId._id))
      } catch (err) {
        console.error("Failed to get saved musics:", err)
      }
    }
    if (userToken) fetchSavedMusics()
  }, [userToken])
  if (!musicDetail?._id) return <LinearProgress color="success" />
  if (error) return <React.Fragment>{error}</React.Fragment>

  return (
    <Page pageId={MusicDetailPage} noPadding={true}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box padding={2} maxWidth={"100%"}>
            <MusicDetailMusic
              {...musicDetail}
              like={musicDetail.like.length}
              description={projectDetail?.description}
              purchaseNumber={musicDetail.purchasedCount}
              image={projectDetail?.image}
              ownedUser={projectDetail?.users?.find((t) => t.role === "LEAD")?.user.name ?? ""}
              ownerId={projectDetail?.users?.find((t) => t.role === "LEAD")?.user._id ?? ""}
              purchased={musicDetail.purchased}
              savedMusics={savedMusics}
              setSavedMusics={setSavedMusics}
              name={musicDetail?.projectName}
            />
            <MusicDetailInformation {...projectDetail} />
          </Box>
          <Box padding={2} maxWidth={"100%"}>
            <Box maxWidth={"100%"} width={"100%"} display={"flex"} justifyContent="space-between">
              <Typography>More about this creator</Typography>
              <Typography align="right" sx={{ cursor: "pointer" }}>
                <Link
                  onClick={() =>
                    navigate(
                      `/user/${
                        projectDetail?.users?.find((t) => t.role === "LEAD")?.user._id ?? ""
                      }/portfolio`
                    )
                  }
                  sx={{
                    textDecoration: "none",
                    zIndex: 1000,
                  }}
                >
                  View More
                </Link>
              </Typography>
            </Box>
            {
              <Grid container>
                {musicsOfUser.map((t: any, i) => {
                  return (
                    <Grid item key={i} xs={12} md={3}>
                      <MusicDetailMusicCard
                        _id={t._id}
                        name={t.projectName}
                        description={t.description}
                        imageUrl={t.imageUrl}
                        image={t.image}
                        saved={t.saved}
                        purchased={t.purchased}
                        price={t.price}
                        project={t.project}
                        savedMusics={savedMusics}
                        setSavedMusics={setSavedMusics}
                        downloadUrl={t.downloadUrl}
                        watermarkUrl={t.watermarkUrl}
                        musicType={t.musicType}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            }
          </Box>

          <Box padding={2} maxWidth={"100%"}>
            <Typography mb={1}>Similar musics</Typography>
            <Box display={"flex"} justifyContent="space-between" mb={1}>
              {projectDetail?.styles?.map((t, i) => {
                return (
                  <Button key={i} variant="outlined">
                    {t}
                  </Button>
                )
              })}
            </Box>
            {
              <Grid container spacing={3}>
                {similarMusics.map((t: any, i) => {
                  return (
                    <Grid item key={i} xs={12} md={3}>
                      <MusicDetailMusicCard
                        _id={t._id}
                        name={t.projectName}
                        description={t.description}
                        imageUrl={t.imageUrl}
                        image={t.image}
                        saved={t.saved}
                        purchased={t.purchased}
                        price={t.price}
                        project={t.project}
                        savedMusics={savedMusics}
                        setSavedMusics={setSavedMusics}
                        downloadUrl={t.downloadUrl}
                        watermarkUrl={t.watermarkUrl}
                        musicType={t.musicType}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            }
          </Box>
        </Grid>
      </Grid>
    </Page>
  )
}
