import { capitalize } from "lodash"
import { config } from "../config/client-config"

const fileServer = config.fileServerUrl

export const getCookieValue = (name: string) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || ""

export const createDownloadUrl = (downloadUrl?: string, token?: string) => {
  let url = downloadUrl ? `${fileServer}/${downloadUrl}` : ""

  if (token && url) {
    url += `?token=${token}`
  }

  return url
}

export const formatPublishDateTime = (publishDateTime: Date | undefined): string => {
  if (!publishDateTime) return ""
  const today = new Date()
  const diffTime = Math.abs(today.getTime() - publishDateTime.getTime())
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
  if (diffDays === 0) {
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60))
    if (diffHours === 0) {
      const diffMinutes = Math.floor(diffTime / (1000 * 60))
      if (diffMinutes === 0) {
        return "Just now"
      }
      return `${diffMinutes} min ago`
    }
    return `${diffHours} h ago`
  }
  if (diffDays < 11) {
    return `${diffDays} d ago`
  }
  return (
    publishDateTime.getDate() + " " + publishDateTime.toLocaleString("en-us", { month: "short" })
  )
}

export const loadScript = (src: string) => {
  // creates a <script> tag and append it to the page
  // this causes the script with given src to start loading and run when complete
  let script = document.createElement("script")
  script.src = src
  document.head.append(script)
}

export { logOut } from "./auth-utils"
export { default as AudioRecorder } from "./recorder"
export { default as Metronome } from "./metronome"

export const rbStringHandling = (str: string) => {
  // Special FE handling for R&B string display
  if (!str) return "--"
  return str?.replace(/\w+/g, capitalize)
}
