import { Typography } from "@mui/material"
import { Link } from "react-router-dom"

/**
 * Copyright Component
 */
export default function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" to="https://womucon.com/">
        Womucon
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}
