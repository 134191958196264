import { useMediaQuery, useTheme } from "@mui/material"

export type useMUIMediaQueryProps = {
  isTabletUp: boolean
  isLapTopUp: boolean
  isMobileUp: boolean
  isMobile: boolean
}

/**
 *
 * Hooks to deal with fequent importing of MUI media query,
 * this is used as a hook instead of function to abide enforce react hooks rule set in useTheme
 *
 * @returns media query used in WMC
 */
export default function useMUIMediaQuery(): useMUIMediaQueryProps {
  const theme = useTheme()
  const isTabletUp = useMediaQuery(theme.breakpoints.up("md"))
  const isLapTopUp = useMediaQuery(theme.breakpoints.up("lg"))
  const isMobileUp = useMediaQuery(theme.breakpoints.up("sm"))
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return {
    isTabletUp,
    isLapTopUp,
    isMobileUp,
    isMobile,
  }
}
