import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material"
import { SubscriptionAPI, SubscriptionPlanAPI } from "../../api"
import { useEffect, useState } from "react"
import SubscriptionPlanCard from "../../component/SubscriptionPlanCard/SubscriptionPlanCard"
import { colors } from "../../theme/colors"
import { Tab } from "./tab"
import { useNavigate } from "react-router-dom"

/**
 * This is the subscription page
 */
export default function SubscriptionPage() {
  const [tab, setTab] = useState<Tab>(Tab.Monthly)
  const [subscribed, setSubscribed] = useState(false)

  const [subscribedPlan, setSubscribedPlan] = useState<string>("")
  const [plans, setPlans] = useState<any[]>([])
  const [error, setError] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    const checkSubscription = async () => {
      const response = await SubscriptionAPI.checkSubscription()
      if (response?.err) {
        setError(response?.err)
        return
      }
      setSubscribed(response?.res?.success || false)
      setSubscribedPlan(response?.res?.subscription?.planType || "")
    }
    checkSubscription()
  }, [])

  useEffect(() => {
    const getAllPlans = async () => {
      const response = await SubscriptionPlanAPI.getAll()
      if (response?.err) {
        setError(response.err)
        return
      }
      setPlans(response?.res?.plans || [])
    }
    getAllPlans()
  }, [])

  // Filter plans based on selected tab (monthly or yearly)
  const filteredPlans = plans.filter(
    (plan) => plan.billingCycle === (tab === Tab.Monthly ? "monthly" : "yearly"),
  )

  return (
    <Paper sx={{ paddingBottom: "15px" }}>
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="h4" align="center" gutterBottom sx={{ padding: 5 }}>
          Subscription plans
        </Typography>
      </Stack>
      <Box margin="5px" display="flex" justifyContent="center">
        <Button
          sx={{
            textTransform: "none",
            backgroundColor: tab === Tab.Monthly ? colors.orange30 : "",
            color: tab === Tab.Monthly ? "#fff" : "",
            paddingX: 6,
          }}
          variant={tab === Tab.Monthly ? "contained" : "outlined"}
          onClick={() => setTab(Tab.Monthly)}
        >
          Monthly
        </Button>
        <Button
          sx={{
            textTransform: "none",
            backgroundColor: tab === Tab.Annually ? colors.orange30 : "",
            color: tab === Tab.Annually ? "#fff" : "",
            paddingX: 6,
          }}
          variant={tab === Tab.Annually ? "contained" : "outlined"}
          onClick={() => setTab(Tab.Annually)}
        >
          Annually
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 2,
          minHeight: "100vh",
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <SubscriptionPlanCard
              title="Free"
              priceText="$0"
              billingCycle={`Billed ${tab === Tab.Monthly ? "monthly" : "yearly"}`}
              features={["Buy any premium music", "Unlimited download the music you’ve bought"]}
              buttonText={!subscribed ? "Existing plan" : ""}
              isExistingPlan={!subscribed}
              planType=""
            />
          </Grid>

          {filteredPlans.map((plan) => (
            <Grid item key={plan._id}>
              <SubscriptionPlanCard
                title={plan.title}
                priceText={`$${plan.price}`}
                billingCycle={`Billed ${plan.billingCycle}`}
                features={plan.features}
                buttonText={
                  plan.isContactUs
                    ? ""
                    : subscribed && subscribedPlan === plan.planType
                      ? "Existing plan"
                      : subscribedPlan.startsWith("monthly") && plan.planType.startsWith("yearly")
                        ? "UPGRADE"
                        : subscribedPlan.endsWith("Creator")
                          ? "UPGRADE"
                          : "Get started"
                }
                isExistingPlan={subscribed && subscribedPlan === plan.planType}
                planType={plan.planType}
                isContactUs={plan.isContactUs}
                onClick={() => {
                  if (plan.isContactUs) {
                    // Handle "Contact Us" action if needed
                  } else {
                    navigate(`/shoppingCart?subscriptionType=${plan.planType}`)
                  }
                }}
              />
            </Grid>
          ))}
          <Grid item>
            <SubscriptionPlanCard
              title="Enterprise"
              priceText="Let's talk"
              billingCycle=""
              features={[
                "Unlimited standard songs download",
                "Monetization for all platforms",
                "Usage right for all platforms",
              ]}
              buttonText=""
              isExistingPlan={false}
              planType={tab === Tab.Monthly ? "monthlyEnterprise" : "yearlyEnterprise"}
              isContactUs={true}
              onClick={() =>
                navigate(
                  `/shoppingCart/${tab === Tab.Monthly ? "monthlyEnterprise" : "yearlyEnterprise"}`,
                )
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
