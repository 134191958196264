import { Box, Button, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { colors } from "../../theme/colors"

/**
 *Subscribe Section
 */
export default function SubscribeSection() {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        py: 4,
        px: 2,
        textAlign: "center",
        borderRadius: 2,

        backgroundColor: colors.darkGrey2,
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
        Got this asset for free & unlimited download for 1 month
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        Subscribe to the monthly plan and you get unlimited download tracks
      </Typography>

      <Stack direction="row" spacing={2} justifyContent="center">
        <Button variant="outlined" sx={{ px: 6 }} onClick={() => navigate("/subscription")}>
          MORE PLANS
        </Button>
        <Button
          variant="contained"
          sx={{ px: 6 }}
          onClick={() => navigate("/shoppingCart?subscriptionType=yearlyStandard")}
        >
          SUBSCRIBE
        </Button>
      </Stack>
    </Box>
  )
}
