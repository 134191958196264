import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode"
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic"
import { Box, Button, Grid, IconButton, Paper, Typography } from "@mui/material"
import { loadStripe } from "@stripe/stripe-js"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { MusicAPI, OrderAPI, SubscriptionAPI, SubscriptionPlanAPI } from "../../api"
import WMC_LOGO from "../../assets/brands/logo-sq.png"
import ShoppingCartMusicItem from "../../component/ShoppingCartMusicItem/ShoppingCartMusicItem"
import SubscriptionPlanCard from "../../component/SubscriptionPlanCard/SubscriptionPlanCard"
import { useCart } from "../../context/CartProvider"
import { Tab } from "../../pages/Subscription/tab"
import { colors } from "../../theme/colors"
import { createDownloadUrl } from "../../util"
import { PurchaseOption } from "./purchaseOption"

import { config } from "../../config/client-config"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import useMUIMediaQuery from "../../hooks/useMUIMedia"

/**
 * Shopping Cart Page
 */
export default function ShoppingCartPage() {
  const { cartItems, fetchCartItems, removeFromCart } = useCart()

  const [searchParams] = useSearchParams()
  const mode = searchParams.get("mode") // Get mode value
  const musicId = searchParams.get("musicId") // Get songId value

  const subscriptionType = searchParams.get("subscriptionType") // Get songId value
  const [cartFetched, setCartFetched] = useState(false)
  const [selectedPlanType, setSelectedPlanType] = useState("")
  const [subscribed, setSubscribed] = useState(false)
  const [plans, setPlans] = useState<any[]>([])
  const [error, setError] = useState("")
  const [isMobileProceed, setIsMobileProceed] = useState(false)
  const [subscribedPlan, setSubscribedPlan] = useState<string>("")
  const { isMobile } = useMUIMediaQuery()
  const [tab, setTab] = useState<Tab>(
    subscriptionType?.startsWith("yearly") ? Tab.Annually : Tab.Monthly,
  )
  const [singleMusic, setSingleMusic] = useState<any>()

  const orderMusics = useMemo(() => {
    if (mode === "single" && !singleMusic) {
      return []
    }
    if (!mode && !cartItems) {
      return []
    }
    return mode === "single" ? [singleMusic] : cartItems
  }, [cartItems, mode, singleMusic])

  const stripePromise = loadStripe(config.stripeKey)
  const [purchaseOption, setPurchaseOption] = useState<PurchaseOption>(
    subscriptionType ? PurchaseOption.subscription : PurchaseOption.oneTime,
  )

  const [totalPrice, setTotalPrice] = useState(0)

  const handleOptionSelect = (option: PurchaseOption) => {
    setPurchaseOption(option)
    if (option === PurchaseOption.subscription) {
      handlePlanSelect(selectedPlanType)
    } else {
      setTotalPrice(orderMusics.reduce((acc, music) => acc + music.price, 0))
    }
  }

  const handlePlanSelect = useCallback(
    (planType: string) => {
      setSelectedPlanType(planType)
      const selectedPlan = plans.find((plan) => plan.planType === planType)
      if (selectedPlan) {
        setTotalPrice(selectedPlan.price)
      } else {
        setTotalPrice(0)
      }
    },
    [plans],
  )

  useEffect(() => {
    if (purchaseOption === PurchaseOption.oneTime) {
      setTotalPrice(orderMusics.reduce((acc, music) => acc + music.price, 0))
    } else if (purchaseOption === PurchaseOption.subscription) {
      handlePlanSelect(selectedPlanType)
    }
  }, [orderMusics, purchaseOption, selectedPlanType, plans, handlePlanSelect])
  useEffect(() => {
    if (subscriptionType) {
      setSelectedPlanType(subscriptionType)
      setPurchaseOption(PurchaseOption.subscription)
      handlePlanSelect(subscriptionType)
    } else {
      setPurchaseOption(PurchaseOption.oneTime)
    }
  }, [handlePlanSelect, subscriptionType])
  useEffect(() => {
    if (!cartFetched) {
      fetchCartItems()
      setCartFetched(true)
    }
  }, [cartFetched, fetchCartItems])

  useEffect(() => {
    const handleGetMusic = async () => {
      try {
        // Request Checkout Session from your backend
        const { res, error } = await MusicAPI.getMusicDetail(musicId)
        if (error) {
          console.error("Error during checkout:", error)
          return
        }
        setSingleMusic(res)
      } catch (error) {
        console.error("Error during checkout:", error)
      }
    }
    if (mode === "single") {
      handleGetMusic()
    }
  }, [mode, musicId])

  useEffect(() => {
    const fetchPlans = async () => {
      const response = await SubscriptionPlanAPI.getAll()
      if (response?.err) {
        setError(response.err)
        return
      }
      setPlans(response?.res?.plans || [])
    }
    fetchPlans()
  }, [])

  useEffect(() => {
    const checkSubscription = async () => {
      const response = await SubscriptionAPI.checkSubscription()
      if (response?.err) {
        setSubscribed(false)
        return
      }
      setSubscribed(response?.res.success)
      setSubscribedPlan(response?.res.subscription?.planType)
    }
    checkSubscription()
  }, [])
  const handleCheckout = async () => {
    try {
      // Request Checkout Session from your backend
      const { res, error } =
        purchaseOption === PurchaseOption.oneTime && mode === "single"
          ? await OrderAPI.createCheckoutSessionByMusicId(musicId)
          : purchaseOption === PurchaseOption.oneTime
            ? await OrderAPI.createCheckoutSession()
            : await SubscriptionAPI.add(selectedPlanType)
      if (error) {
        console.error("Error during checkout:", error)
        return
      }
      const sessionId = res.sessionId

      // Redirect to Stripe Checkout
      const stripe = await stripePromise
      if (stripe) {
        await stripe.redirectToCheckout({ sessionId })
      } else {
        console.error("Stripe is not loaded")
      }
    } catch (error) {
      console.error("Error during checkout:", error)
    }
  }

  // Filter plans based on selected tab (monthly or yearly)
  const filteredPlans = plans.filter(
    (plan) => plan.billingCycle === (tab === Tab.Monthly ? "monthly" : "yearly"),
  )

  return (
    <Box sx={{ padding: 4, minHeight: "100vh" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Your shopping cart
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={7}>
          {purchaseOption === PurchaseOption.subscription && (
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Box margin="5px" display="flex" justifyContent="center">
                  <Button
                    sx={{
                      textTransform: "none",
                      backgroundColor: tab === Tab.Monthly ? colors.orange30 : "",
                      color: tab === Tab.Monthly ? "#fff" : "",
                      paddingX: 6,
                    }}
                    variant={tab === Tab.Monthly ? "contained" : "outlined"}
                    onClick={() => setTab(Tab.Monthly)}
                  >
                    Monthly
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      backgroundColor: tab === Tab.Annually ? colors.orange30 : "",
                      color: tab === Tab.Annually ? "#fff" : "",
                      paddingX: 6,
                    }}
                    variant={tab === Tab.Annually ? "contained" : "outlined"}
                    onClick={() => setTab(Tab.Annually)}
                  >
                    Annually
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    sx={{
                      padding: { xs: 4, sm: 4 },
                      maxWidth: "1200px",
                      margin: "0 auto",
                    }}
                  >
                    {filteredPlans.map((plan) => (
                      <Grid item xs={12} sm={6} md={4} key={plan._id}>
                        <SubscriptionPlanCard
                          title={plan.title}
                          priceText={`$${plan.price}`}
                          billingCycle={`Billed ${plan.billingCycle}`}
                          features={plan.features}
                          buttonText={plan.isContactUs ? "" : "Select"}
                          isExistingPlan={subscribedPlan === plan.planType}
                          planType={plan.planType}
                          onClick={() => handlePlanSelect(plan.planType)}
                          isSelected={selectedPlanType === plan.planType}
                          isContactUs={plan.isContactUs}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12} sm={6} md={4}>
                      <SubscriptionPlanCard
                        title="Enterprise"
                        priceText="Let's talk"
                        billingCycle=""
                        features={[
                          "Unlimited standard songs download",
                          "Monetization for all platforms",
                          "Usage right for all platforms",
                        ]}
                        buttonText=""
                        isExistingPlan={false}
                        planType={tab === Tab.Monthly ? "monthlyEnterprise" : "yearlyEnterprise"}
                        isContactUs={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {orderMusics.length !== 0 && (
            <Paper sx={{ padding: 2, marginTop: 2 }}>
              {purchaseOption !== PurchaseOption.oneTime && (
                <Typography variant="h6" gutterBottom>
                  {`With standard ${orderMusics?.length} tracks you've selected`}
                </Typography>
              )}
              {orderMusics.map((music, index) => (
                <ShoppingCartMusicItem
                  key={index}
                  title={music.project.name}
                  artist={
                    music.project?.users?.find((t: any) => t.role === "LEAD")?.user.name ?? ""
                  }
                  price={purchaseOption !== PurchaseOption.oneTime ? 0 : music.price}
                  format={music.fileType}
                  imageUrl={createDownloadUrl(music?.project?.image?.downloadUrl) || WMC_LOGO}
                  onRemove={() => removeFromCart(music._id)}
                />
              ))}
            </Paper>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            sx={{
              padding: 2,
              backgroundColor: colors.darkGrey2,
              position: { xs: "fixed", md: "relative" }, // Fixed on mobile, relative on larger screens
              bottom: { xs: 0, md: "auto" }, // Stick to the bottom on mobile
              left: { xs: 0, md: "auto" }, // Full-width on mobile
              right: { xs: 0, md: "auto" },
              zIndex: 10, // Ensure it appears above other content
              borderTop: { xs: `1px solid ${colors.grey}`, md: "none" }, // Add top border for mobile
            }}
          >
            {((isMobile && isMobileProceed) || !isMobile) && (
              <>
                <Box
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <Typography variant="h6" gutterBottom>
                    Purchasing options
                  </Typography>
                  {isMobile && (
                    <IconButton onClick={() => setIsMobileProceed(false)}>
                      {<ExpandMoreIcon />}
                    </IconButton>
                  )}
                </Box>
                {orderMusics.length !== 0 && (
                  <Box
                    sx={{
                      padding: 2,
                      border:
                        purchaseOption === PurchaseOption.oneTime
                          ? `1px solid ${colors.orange}`
                          : `1px solid ${colors.grey}`,
                      borderRadius: 1,
                      marginBottom: 2,
                      cursor: "pointer",
                      display: "flex",
                    }}
                    onClick={() => handleOptionSelect(PurchaseOption.oneTime)}
                  >
                    <ChromeReaderModeIcon />
                    <Typography variant="body1" sx={{ color: "#fff" }} ml={2}>
                      One time purchase
                    </Typography>
                  </Box>
                )}

                {!subscribed && (
                  <Box
                    sx={{
                      padding: 2,
                      border:
                        purchaseOption === PurchaseOption.subscription
                          ? `1px solid ${colors.orange}`
                          : `1px solid ${colors.grey}`,
                      borderRadius: 1,
                      marginBottom: 2,
                      cursor: subscribed ? "not-allowed" : "pointer",
                      pointerEvents: subscribed ? "none" : "auto",
                      display: "flex",
                    }}
                    onClick={() => handleOptionSelect(PurchaseOption.subscription)}
                  >
                    <LibraryMusicIcon />
                    <Typography variant="body1" sx={{ color: "#fff" }} ml={2}>
                      Subscribe Our Plans
                    </Typography>
                  </Box>
                )}
              </>
            )}

            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
              <Typography variant="h5">Total</Typography>
              <Typography variant="h5">HKD {totalPrice}</Typography>
            </Box>
            {(isMobile && isMobileProceed) || !isMobile ? (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: colors.orange,
                  color: "#fff",
                  textTransform: "none",
                  padding: 1.5,
                  borderRadius: 1,
                }}
                disabled={
                  (orderMusics.length === 0 && purchaseOption === PurchaseOption.oneTime) ||
                  (purchaseOption === PurchaseOption.subscription && !selectedPlanType)
                }
                onClick={handleCheckout}
              >
                Proceed to Checkout
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: colors.orange,
                  color: "#fff",
                  textTransform: "none",
                  padding: 1.5,
                  borderRadius: 1,
                }}
                onClick={() => setIsMobileProceed(true)}
              >
                View My Order
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}
