import { Navigate } from "react-router-dom"
import { useUserInfo } from "../context"

/**
 * Private route for normal users
 */
export default function PrivateRoute({ Component }: { Component: any }) {
  const { userToken } = useUserInfo()
  return userToken ? <Component /> : <Navigate to="/" />
}
