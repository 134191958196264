import { Dispatch, SetStateAction, useState } from "react"
import { Box, Button, IconButton, Modal, Typography } from "@mui/material"
import FilterMenuItem from "./FilterMenuItem"
import { FilterState } from "../../../types/marketPlace"
import CloseIcon from "@mui/icons-material/Close"
import { rbStringHandling } from "../../../util"
import { startCase } from "lodash"
// @ts-ignore
import { musicStyle } from "@womucon/share"

/**
 * Filter menu in market place
 */
export default function MobileMarketPlaceFilterMenu({
  open,
  onClose,
  filter,
  setFilter,
}: {
  open: boolean
  onClose: () => void
  filter: FilterState
  setFilter: Dispatch<SetStateAction<FilterState>>
}) {
  const [tempStyle, setTempStyle] = useState(filter?.style ?? [])

  const updateFilter = (newStyle: string) => {
    if (!newStyle) return

    const updatedStyles = tempStyle?.includes(newStyle)
      ? tempStyle?.filter((style) => style !== newStyle)
      : [...tempStyle, newStyle]

    setTempStyle(updatedStyles)
  }

  const onCloseBtnPress = () => {
    setTempStyle(filter?.style)
    onClose()
  }

  const resetFilter = () => setTempStyle([])

  const onConfirm = () => {
    setFilter((prevFilter) => {
      return { ...prevFilter, style: tempStyle }
    })
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          <IconButton
            edge="end"
            onClick={() => onCloseBtnPress()}
            aria-label="close filtering modal"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="body1">Filter by music style</Typography>
          <Button sx={{ textTransform: "none" }} onClick={() => resetFilter()}>
            Clear All
          </Button>
        </Box>

        <Box>
          {musicStyle?.map((item: string) => {
            const focused = tempStyle?.includes(item)
            return (
              <FilterMenuItem
                key={`style_${item}`}
                image={item}
                focused={focused}
                label={item === "r&b" ? rbStringHandling(item) : startCase(item)}
                onClick={() => updateFilter(item)}
              />
            )
          })}
        </Box>

        <Button variant="contained" onClick={() => onConfirm()} sx={{ width: "100%", my: 2 }}>
          Confirm
        </Button>
      </Box>
    </Modal>
  )
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "65%",
  px: 4,
  py: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "scroll",
  // border: "2px solid #000",
}
