import * as React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import Link from "@mui/material/Link"
import AppBar from "@mui/material/AppBar"
import DrawerMenu from "./DrawerMenu"
import AppBarMenu from "./AppBarMenu"

const DRAWER_WIDTH = 280

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}))

/**
 * DrawerAppBar component
 */
export default function DrawerAppBar({ children }: { children: React.ReactNode }) {
  // const isMobile = window.innerWidth <= 600
  // const isLoginPage = location.pathname === "/" || location.pathname === "/auth"

  // close app drawer when is mobile and in root page
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => setOpen(true)
  const handleDrawerClose = () => setOpen(false)

  return (
    <Box sx={{ display: "flex", paddingBottom: "80px" }}>
      <AppBar position="fixed" color="primary" sx={{ minHeight: "65px", justifyContent: "center" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <IconButton
              edge="start"
              onClick={handleDrawerOpen}
              sx={{ mr: 1 }}
              aria-label="open drawer"
            >
              <MenuIcon />
            </IconButton>
            <Link href="/">
              <Logo />
            </Link>
          </Box>

          <AppBarMenu />
        </Toolbar>
      </AppBar>

      <Drawer
        open={open}
        onClose={handleDrawerClose}
        sx={{
          width: DRAWER_WIDTH,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
          },
        }}
        anchor="left"
      >
        <Box onClick={handleDrawerClose}>
          <DrawerHeader sx={{ gap: 1 }}>
            <IconButton>
              <MenuIcon />
            </IconButton>
            <Link href="/">
              <Logo />
            </Link>
          </DrawerHeader>

          <DrawerMenu />
        </Box>
      </Drawer>

      <Box sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  )
}

/**
 * App logo
 */
// eslint-disable-next-line react/no-multi-comp
function Logo() {
  return (
    <img
      src={"https://www.womucon.com/wp-content/uploads/2023/03/logo.png"}
      alt="logo"
      style={{
        height: 48,
        verticalAlign: "middle",
      }}
      loading="lazy"
    />
  )
}
