import { ErrorBoundary } from "react-error-boundary"
import { Navigate, Route, Routes } from "react-router-dom"
import { ErrorFallback } from "../component"
import { useUserInfo } from "../context"
import AcknowledgementPage from "../pages/Acknowledgement/AcknowledgementPage"
import Auth from "../pages/Auth/Auth"
import BrowseMusicPage from "../pages/BrowserMusic/BrowseMusicPage"
import ContactUs from "../pages/ContactUs/ContactUsPage"
import EditProjectDetailPage from "../pages/EditProjectDetail/EditProjectDetailPage"
import HistoryPage from "../pages/History/HistoryPage"
import MarketPlacePage from "../pages/MarketPlace/MarketPlacePage"
import MusicDetailPage from "../pages/MusicDetail/MusicDetailPage"
import MyProjectsPage from "../pages/MyProjects/MyProjectsPage"
import PortfolioPage from "../pages/Portfolio/PortfolioPage"
import SelfPortfolioPage from "../pages/Portfolio/SelfPortfolioPage"
import ProjectDetailPage from "../pages/ProjectDetail/ProjectDetailPage"
import Projects from "../pages/Projects/ProjectsPage"
import ShoppingCartPage from "../pages/ShoppingCart/ShoppingCartPage"
import SignUpUserPage from "../pages/Signup/SignupPage"
import StartProject from "../pages/StartProject/StartProject"
import SubscriptionPage from "../pages/Subscription/SubscriptionPage"
import SubscriptionAcknowledgementPage from "../pages/SubscriptionAcknowledgement/SubscriptionAcknowledgementPage"
import UploadSongPage from "../pages/UploadSong/UploadSongPage"
import EditUser from "../pages/User/EditUser"
import VerifyUser from "../pages/VerifyUser/VerifyUser"
import PrivateRoute from "./PrivateRoute"
import { useCheckIsAdmin } from "../hooks/useCheckIsAdmin"

/**
 * App Router
 */
export default function AppRouter() {
  const { userToken } = useUserInfo()
  const { isAdmin } = useCheckIsAdmin()
  const defaultPath = "/marketPlace"

  return (
    <Routes>
      <Route path="/" element={userToken ? <Navigate replace to={defaultPath} /> : <Auth />} />
      <Route path="/auth" element={userToken ? <Navigate replace to={defaultPath} /> : <Auth />} />
      <Route
        path="/signup"
        element={userToken ? <Navigate replace to={defaultPath} /> : <SignUpUserPage />}
      />
      <Route path="/createUser" element={<PrivateRoute Component={Auth} />} />
      <Route path="/user/:userId/portfolio" element={<PrivateRoute Component={PortfolioPage} />} />
      <Route path="/user/self/portfolio" element={<PrivateRoute Component={SelfPortfolioPage} />} />
      <Route path="/user/self/projects" element={<PrivateRoute Component={MyProjectsPage} />} />
      <Route path="/user/self/edit" element={<PrivateRoute Component={EditUser} />} />
      <Route path="/projects" element={<PrivateRoute Component={Projects} />} />
      <Route path="/projects/:projectId" element={<PrivateRoute Component={ProjectDetailPage} />} />
      <Route
        path="/projects/:projectId/edit"
        element={<PrivateRoute Component={EditProjectDetailPage} />}
      />
      <Route
        path="/projects"
        element={
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Projects />
          </ErrorBoundary>
        }
      />
      <Route path="/startProject" element={isAdmin && <PrivateRoute Component={StartProject} />} />
      <Route path="/uploadSong" element={isAdmin && <PrivateRoute Component={UploadSongPage} />} />
      <Route path="/marketPlace" element={<PrivateRoute Component={MarketPlacePage} />} />
      <Route path="/music" element={<PrivateRoute Component={BrowseMusicPage} />} />
      <Route path="/musicDetail/:musicId" element={<PrivateRoute Component={MusicDetailPage} />} />
      <Route path="/verify/user" element={<PrivateRoute Component={VerifyUser} />} />
      <Route path="/subscription" element={<PrivateRoute Component={SubscriptionPage} />} />
      <Route
        path="/subscriptionAcknowledgement/:subscriptionId"
        element={<PrivateRoute Component={SubscriptionAcknowledgementPage} />}
      />
      <Route path="/shoppingCart" element={<PrivateRoute Component={ShoppingCartPage} />} />
      <Route
        path="/shoppingCart/:subscriptionType"
        element={<PrivateRoute Component={ShoppingCartPage} />}
      />
      <Route
        path="/acknowledgement/:orderId"
        element={<PrivateRoute Component={AcknowledgementPage} />}
      />
      <Route path="/history/:tab" element={<PrivateRoute Component={HistoryPage} />} />

      <Route path="/contactus" element={<ContactUs />} />
      <Route path="*" element={<Navigate replace to={defaultPath} />} />
    </Routes>
  )
}
