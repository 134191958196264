import { useState } from "react"
import { createDownloadUrl } from "../../util"
import MusicItem from "./MusicItem"

export interface MusicItemProp {
  styles: string[]
  instruments: string[]
  like: string[]
  _id: string
  name: string
  project: string
  projectName: string
  downloadUrl: string
  image: {
    downloadUrl?: string
  }
}

export interface MusicListProp {
  allMusic: MusicItemProp[]
  handlePlayMusic: (_url: string) => void
  handleStopMusic: () => void
}

/**
 * Music List
 */
export default function MusicList(props: MusicListProp) {
  const { allMusic, handlePlayMusic, handleStopMusic } = props
  const initStateMap: { [key: string]: boolean } = {}
  allMusic.forEach((music) => {
    initStateMap[music._id] = false
  })
  const [playState, setPlayState] = useState<{ [key: string]: boolean }>(initStateMap)

  return (
    <>
      {allMusic.map((music, idx) => {
        const { _id, name, projectName, image, downloadUrl } = music
        const musicUrl = createDownloadUrl(downloadUrl)
        const musicImageUrl = createDownloadUrl(image?.downloadUrl)

        return (
          <MusicItem
            key={idx}
            id={_id}
            playState={playState[_id]}
            name={name}
            projectName={projectName}
            musicUrl={musicUrl}
            musicImageUrl={musicImageUrl}
            handlePlayMusic={() => {
              setPlayState({ ...initStateMap, ...{ [_id]: true } })
              handlePlayMusic(musicUrl)
            }}
            handleStopMusic={() => {
              setPlayState({ ...playState, ...{ [_id]: false } })
              handleStopMusic()
            }}
          />
        )
      })}
    </>
  )
}
