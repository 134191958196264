import { config } from "../config/client-config"
import { getRequest, postRequest } from "./request"

const Order: any = {}

Order.createCheckoutSession = async () => {
  return await postRequest({ url: "/order/createCheckoutSession" })
}

Order.createCheckoutSessionByMusicId = async (musicId: string) => {
  return await postRequest({ url: "/order/createCheckoutSessionByMusicId", data: { musicId } })
}

Order.getOrders = async () => {
  return await getRequest({ url: `/order/orders` })
}

Order.getOrderById = async (orderId: string) => {
  return await getRequest({ url: `/order/order/${orderId}` })
}

export default Order
