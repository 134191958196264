import React from "react"
import { Stack, Typography, Grid, Button, Box } from "@mui/material"
import { colors } from "../../../theme/colors"
import { Modal, TaskForm, TaskItem } from "../../../component"
import { TaskProps } from "../../../types"
import { createDownloadUrl } from "../../../util"

const taskContainerStyle = {
  padding: 1,
}

const taskStackStyle = {
  // Task stack
  overflowY: "auto",
  height: "calc(100vh - 180px)",
  "> div": {
    // Each Task
    paddingBottom: 1,
    "& div:nth-of-type(2)": {
      // Task words
      wordBreak: "break-word",
      paddingLeft: 0,
      paddingRight: 0,
      "& span": {
        color: colors.grey,
      },
    },
    "& div:nth-of-type(3)": {
      // Task Avator
      textAlign: "right",
    },
  },
}

const taskBottonBoxStyle = {
  textAlign: "center",
  padding: 2,
  "> button": {
    width: "70%",
  },
}

export interface TaskComponentProps {
  users: never[]
  tasks: Array<TaskProps>
  projectId: string
  handleCreateTask: Function
  handleEditTask: Function
  handleDeleteTask: Function
}

/**
 * Project Detail Task Panel
 */
export default function Task(props: TaskComponentProps) {
  const {
    tasks,
    users: rawUsers,
    projectId,
    handleCreateTask,
    handleEditTask,
    handleDeleteTask,
  } = props
  const users = rawUsers?.reduce((acc: any, role: any) => {
    if (role.status === "ACTIVE") acc.push({ name: role.user.name, id: role.user._id })
    return acc
  }, [])
  const [isTaskModalOpen, setTaskModelOpen] = React.useState(false)
  const [selectedEditTask, setSelectedEditTask] = React.useState<undefined | TaskProps>()
  const handleSelectEditTask = (task: TaskProps) => {
    setSelectedEditTask(task)
    setTaskModelOpen(true)
  }
  const handleDelete = () => {
    if (selectedEditTask) {
      handleDeleteTask(selectedEditTask._id)
    }
    setSelectedEditTask(undefined)
    setTaskModelOpen(false)
  }
  const handleFormSubmit = (newTaskValue: any) => {
    if (selectedEditTask) {
      handleEditTask(selectedEditTask._id, newTaskValue)
    } else {
      createTask(newTaskValue)
    }
    setTaskModelOpen(false)
  }
  const handleTaskCheckbox = (taskId: string, checked: boolean) => {
    if (checked) {
      handleEditTask(taskId, { status: "FINISHED" })
    } else {
      handleEditTask(taskId, { status: "OPEN" })
    }
  }
  const createTask = (newTaskValue: any) => {
    const data = {
      ...newTaskValue,
      projectId,
    }
    handleCreateTask(data)
  }
  const handleCloseModal = () => {
    setSelectedEditTask(undefined)
    setTaskModelOpen((prev) => !prev)
  }

  const taskItems = tasks.map((task: TaskProps, idx: React.Key) => {
    const { description, name, status, deadline, assignTo, _id: taskId } = task
    const { name: userName, _id, profileImage } = assignTo
    const userUrl = `/user/${_id}/portfolio`
    const profileImageUrl = createDownloadUrl(profileImage?.downloadUrl)
    return (
      <TaskItem
        key={idx}
        name={name}
        description={description}
        deadline={new Date(deadline).toLocaleDateString()}
        userImg={profileImageUrl}
        userName={userName}
        userUrl={userUrl}
        done={status === "FINISHED"}
        handleEdit={() => handleSelectEditTask(task)}
        handleTaskCheckbox={(checked: boolean) => handleTaskCheckbox(taskId, checked)}
      />
    )
  })
  return (
    <Grid container sx={taskContainerStyle}>
      <Grid item xs={12}>
        <Typography variant="h5">Task List</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column" sx={taskStackStyle}>
          {taskItems}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Box sx={taskBottonBoxStyle}>
          <Button variant="contained" onClick={() => setTaskModelOpen(true)}>
            Create Task
          </Button>
          <Modal open={isTaskModalOpen} handleToggleClose={handleCloseModal}>
            <TaskForm
              users={users}
              handleFormSubmit={handleFormSubmit}
              selectedTask={selectedEditTask}
              handleDelete={handleDelete}
            />
          </Modal>
        </Box>
      </Grid>
    </Grid>
  )
}
