import React from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

interface IconWithTextProps {
  /**
   * An Mui Icon
   */
  iconComponent: React.ReactElement
  /**
   * Text to the right of the Icon
   */
  text: string
}

/**
 * A Componet that displays Icon to the left and text to the right
 */
export default function IconWithText({ iconComponent, text }: IconWithTextProps) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {iconComponent}
      <Typography variant="body1">{text}</Typography>
    </Stack>
  )
}
